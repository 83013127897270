.user-image {
  width: var(--spacing-64);
  height: var(--spacing-64);
  margin-right: var(--spacing-12);
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--color-violet-400);
  padding: 2px;
}

.line-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: black;
}

.line-text {
  padding: 0 10px;
}
