@import url("https://fonts.googleapis.com/css2?family=Playball&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Quicksand:wght@500&display=swap");
@import url("assets/css/common.css");
@import url("assets/css/navbar.css");
@import url("assets/css/settings.css");
@import url("assets/css/user.css");
* {
  /* font-family: var(--font-family-display); */
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
