.settings-wrapper .settings-list {
  list-style: none;
  padding: 0;
}

.settings-wrapper .settings-list li {
  padding: 0.7rem 0;
  align-items: center;
  cursor: pointer;
}
