.navbar {
  background-color: var(--color-violet-400) !important;
}

.navbar-toggler {
  border: none !important;
  background: white !important;
  font-size: small !important;
}

.img-nmc-logo {
  width: 350px;
  object-fit: contain;
}
